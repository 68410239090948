/* eslint-disable */

import ReactPlayer from 'react-player';
import { Row } from 'react-bootstrap';

export function WebCamera({ webcamUrl }) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const WIDTH = window.innerWidth;

  return (
    <>
        <ReactPlayer
          url={webcamUrl}
          style={{
            // border: '5px',
            margin: 'auto',
            width: '40%',
            // padding: '10px'
          }}
          // width={`${WIDTH}`}
          width={'100%'}
          height={'40%'}
          controls
          playsinline
          playing
          muted
          config={{
            forceHLS: !isSafari,
            forceVideo: true,
            hlsVersion: '0.12.4',
            attributes: {
              disablePictureInPicture: true,
            },
          }}
        />
    </>
  );
}
