import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import { AccordionSummary, AccordionDetails, Accordion } from '@mui/material';
import { Col, Dropdown, Navbar, Row } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  `;

export const ModalBg = styled.div`
  background: ${({ theme }) => theme.body};
`;

export const Footer = styled.footer`
  background: ${({ theme }) => theme.body};
`;

export const LinksColor = styled(Link)`
  color: ${({ theme }) => theme.a};
  display: flex;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.a};
  }

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const NavBar = styled(Navbar)`
  background-color: ${({ theme }) => theme.navBarBg};
`;

export const LoginBg = styled.div`
  background-color: ${({ theme }) => theme.navBarBg};
`;

export const NavLink = styled.div`
  display: flex;
`;

export const Translate = styled.button`
  background-color: ${({ theme }) => theme.translateBg};
  color: ${({ theme }) => theme.translateText};
`;

export const HomeCard = styled.div`
  background-color: ${({ theme }) => theme.navBarBg};
`;

export const CardLink = styled(Link)`
  color: ${({ theme }) => theme.a};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.a};
  }
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const MainScreenLink = styled(Col)`
  color: ${({ theme }) => theme.a};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.a};
  }
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const PowerMetricsColor = styled.p`
  color: ${({ theme }) => theme.powerMetricColor};
`;

export const OfferLinksColor = styled.a`
  color: ${({ theme }) => theme.offerLinksColor};
  &:hover {
    color: ${({ theme }) => theme.a};
  }
`;

export const ModalTextColorLink = styled.a`
  color: ${({ theme }) => theme.text};
  &:hover {
    color: ${({ theme }) => theme.text};
  }
`;

export const FooterLink = styled.a`
  color: ${({ theme }) => theme.footerColorLink};
`;

export const VoltageBtn = styled(Col)`
  color: ${({ theme }) => theme.a};

  background-color: ${({ theme }) => theme.navBarBg};
`;

export const FullInfoContainer = styled.div`
  background-color: ${({ theme }) => theme.navBarBg};
`;

export const SliderContainer = styled.div`
  background-color: ${({ theme }) => theme.navBarBg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 32rem;
  padding: 0 15px 0 15px;
  border-radius: 20px;
`;

export const FinishKmStap = styled(Row)`
  color: ${({ theme }) => theme.powerMetricColor};
`;

export const FinishKwtStap = styled.div`
  color: ${({ theme }) => theme.text};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.text};
`;

export const DropDownMenu = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.navBarBg};
  box-shadow: 0 10px 17px -3px rgba(0, 0, 0, 0.075);
`;

export const AccordionCustom = styled(Accordion)`
  @media (min-width: 400px) and (max-width: 420px) {
    // min-width: 399px;
  }
  @media (min-width: 421px) and (max-width: 440px) {
    // min-width: 420px;
  }
  @media (min-width: 441px) and (max-width: 460px) {
    // min-width: 440px;
  }
  @media (min-width: 461px) and (max-width: 480px) {
    // min-width: 460px;
  }
  @media (min-width: 1024px) {
    // min-width: 1023px;
  }
`;

export const AccordionSummaryCustom = styled(AccordionSummary)`
  &.MuiButtonBase-root {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
`;
export const AccordionDetailsCustom = styled(AccordionDetails)`
  background: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
`;

export const ExpandMoreIconCustom = styled(ExpandMoreIcon)`
  color: ${({ theme }) => theme.text};

  &.MuiSvgIcon-root {
    width: 2.5rem;
    height: 2.5rem;
    color: ${({ theme }) => theme.offerLinksColor};
  }
`;

export const ColCustom = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px ${({ theme }) => theme.text} solid;
  border-collapse: collapse;
  font-size: 12px;
  min-height: 4rem;

  @media (min-width: 576px) {
    font-size: 14px;
  }
`;

export const HistoryLink = styled(Link)`
  color: ${({ theme }) => theme.text};

  &:hover {
    color: #8f00ff;
  }
`;
