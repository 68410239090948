import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import './i18n';
import { Provider } from 'react-redux';
import { setupStore } from './redux/store';

const store = setupStore;
const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
