import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

export const getClientId = () => {
  let id = localStorage.getItem('clientId');
  if (!id) {
    id = uuidv4();
    localStorage.setItem('clientId', id);
  }
  return id;
};

export const getClientAgent = () => {
  return navigator.userAgent;
};

export const useGetClientId = () => {
  const [clientId, setClientId] = useState(localStorage.getItem('clientId'));

  if (clientId) return clientId;

  const setUUID = () => {
    const id = uuidv4();
    localStorage.setItem('clientId', id);
    setClientId(id);
  };

  if (process.env.REACT_APP_FINGERPRINT_ID === 'NO_ID') {
    setUUID();
    return clientId;
  }

  const fpPromise = FingerprintJS.load({
    apiKey: process.env.REACT_APP_FINGERPRINT_ID,
    region: 'eu',
  });

  (async () => {
    try {
      const fp = await fpPromise;
      const fingerPrintData = await fp.get();

      const id = fingerPrintData.visitorId || uuidv4();
      localStorage.setItem('clientId', id);
      setClientId(id);
    } catch {
      setUUID();
    }
  })();

  return clientId;
};
