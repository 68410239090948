export const percentageCharged = (periodCharge, left, type, setRangeError) => {
  let period = periodCharge;
  if (type === 'KWT') {
    period = periodCharge * 1000;
  }

  if (period <= 0 || left > period) {
    setRangeError(true);
  }
  let difference = period - left;
  let leftPercent = (difference / period) * 100;
  return leftPercent.toFixed(2);
};
