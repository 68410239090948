/* eslint-disable */
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import NotFoundPage from './pages/notFoundPage/NotFoundPage';
import PrivateRoute from './pages/startPage/privateRoute/PrivateRoute';
import StartPage from './pages/startPage/StartPage';
import ChargingPage from './pages/chargingPage/ChargingPage';
import ContactsPage from './pages/contactsPage/ContactsPage';
import TermsPage from './pages/termsPage/TermsPage';
import ReactGA from 'react-ga';
import B2bPage from './pages/b2bPage/B2bPage';
import RedirectLogin from './pages/b2bPage/redirectLogin/RedirectLogin';
import { ENV } from './environment';
import StationNumberLayout from './components/layouts/stationNumberLayout/StationNumberLayout';
import HistoryPage from './pages/historyPage/historyPage';
import ScannerPage from './pages/scannerPage/ScannerPage';
import StartErrorPage from './pages/startErrorPage/startErrorPage';
import HomeMapPage from './pages/homePage/HomeMapPage';
import HomeListPage from './pages/homePage/HomeListPage';
import { clarity } from 'react-microsoft-clarity';
import React, { useEffect, useState } from 'react';

const TRACKING_ID = {
  prod: 'UA-246535650-1',
  stage: 'UA-246535650-2',
};

if (ENV === 'prod') {
  ReactGA.initialize(TRACKING_ID.prod);
  clarity.init('opcn7h9p5n');
} else if (ENV === 'stage') {
  ReactGA.initialize(TRACKING_ID.stage);
  clarity.init('opco080tcx');
}

function App() {
  return (
    <Suspense fallback={null}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path={'/scanner'} element={<ScannerPage />} />
            <Route path="/startError" element={<StartErrorPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/oauth2/redirect" element={<RedirectLogin />} />
            <Route path="/b2b" element={<B2bPage />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/contract" element={<TermsPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route element={<StationNumberLayout />}>
              <Route path="/charging" element={<ChargingPage />} />
              <Route
                path="/start"
                element={
                  <PrivateRoute>
                    <StartPage />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path={'/'} element={<HomeMapPage />} />
            <Route path={'/list'} element={<HomeListPage />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
