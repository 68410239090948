import React from 'react';
import './localLoading.css';

export function LoadingTime() {
  return <div className="loading"></div>;
}

export function LoadingSm() {
  return (
    <div
      id="localLoadingBox"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div id="localLoadingAnimation" className="loading loadingChar"></div>
    </div>
  );
}
