import cn from 'classnames';
import styles from './card.module.css';

export const Card = ({ active, children, className, variant, ...props }) => {
  return (
    <div
      className={cn(styles.container, active && styles.active, variant === 'invert' && styles.invert, className)}
      {...props}
    >
      {children}
    </div>
  );
};
