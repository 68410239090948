/* eslint-disable */
import {useEffect, useState} from 'react';
import ModalContacts from '../contactsPage/modal/ModalContacts';
import {useTranslation} from 'react-i18next';
import './startErrorPage.css';
import {Button} from '../../shared/ui';

const StartErrorPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  //eslint-disable-next-line
  const [pathName, portNumber] = window.location.href.split('=');
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isOpen, setIsOpen] = useState(false);

  function callToSupport() {
    setIsOpen(true);
  }

  return (
      <>
        <div className={'errorStart-container'}>
          {!isOpenModal && (
              <>
                <div className={'errorStart-message'}>
                  <span>{t('errorStart.alertMessagePort', {portNumber})}</span>
                  <br/>
                  {t('errorStart.alertMessage')}
                </div>
                <br/>
                <Button className="mainBtn buttonForOfflineStation w-100"
                        link={`/start?station=${portNumber}`}>
                  <span className="mainBtnText"> {t('startNew')}</span>

                </Button>
                <Button
                    onClick={callToSupport}
                    className="mainBtn buttonForOfflineStation w-100"
                >
                  <span className="mainBtnText">{t('contactUs')}</span>
                </Button>
              </>
          )}
          <ModalContacts modalOpen={isOpen} setModalOpen={setIsOpen}/>
        </div>
      </>
  );
};

export default StartErrorPage;
