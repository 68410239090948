/* eslint-disable */
import '../../components/buttons/mainBtn.css';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../errorPage/ErrorPage';
import Spinner from '../../components/loaders/globalSpinner/Spinner';
import { api, useGetBloodyLitersSavedQuery } from '../../redux/api';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGeolocationWithPermission } from '../../hookServices/getGeolocationWithPermission';
import { ENV } from '../../environment';
import './HomePage.css';
import SVGContainer from '../../components/svgComponents/SVGContainer';
import SVGACFilter from '../../components/svgComponents/SVGACFilter';
import SVGDCFilter from '../../components/svgComponents/SVGDCFilter';
import SVGBanner from '../../components/svgComponents/SVGBanner';
import { getCookie } from '../../hookServices/cookiesManager';
import Map from './Map';

export default function HomeMapPage({ fingerPrint }) {
  const [filter, setFilter] = useState('ac');

  function setACFilter() {
    fetchTokaPorts('ac');
    fetchUgvPorts('ac');
    fetchKm220Ports('ac');
    setFilter('ac');
  }

  function setDCFilter() {
    fetchTokaPorts('dc');
    fetchUgvPorts('dc');
    fetchKm220Ports('dc');
    setFilter('dc');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setACFilter();
  }, []);

  const navigate = useNavigate();
  const { t } = useTranslation();

  function useTokaCall() {
    const [fetchTokaApi] = api.useLazyGetTokaPortsQuery();
    const [tokaPorts, setTokaPorts] = useState();

    const fetchTokaPorts = async acdc => {
      const { data } = await fetchTokaApi({ acdc });
      setTokaPorts(data);
    };

    return { tokaPorts, fetchTokaPorts };
  }

  const { tokaPorts, fetchTokaPorts } = useTokaCall();

  function useUgvCall() {
    const [fetchUgvApi] = api.useLazyGetUgvPortsQuery();
    const [ugvPorts, setUgvPorts] = useState();

    const fetchUgvPorts = async acdc => {
      const { data } = await fetchUgvApi({ acdc });
      setUgvPorts(data);
    };

    return { ugvPorts, fetchUgvPorts };
  }

  const { ugvPorts, fetchUgvPorts } = useUgvCall();

  function useKm220Call() {
    const [fetchKm220Api] = api.useLazyGetKm220PortsQuery();
    const [km220Ports, setKm220Ports] = useState();

    const fetchKm220Ports = async acdc => {
      const { data } = await fetchKm220Api({ acdc });
      setKm220Ports(data);
    };

    return { km220Ports, fetchKm220Ports };
  }

  const { km220Ports, fetchKm220Ports } = useKm220Call();

  function showMap() {
    return <Map tokaPorts={tokaPorts} ugvPorts={ugvPorts} km220Ports={km220Ports} />;
  }

  return (
    <div id="homePage">
      {showMap()}
     {/*  <div
        onClick={() => {
          navigate('/scanner');
        }}
        className={'qr-button'}
        style={{ top: '74lvh' }}
      >
        <SVGContainer>
          <SVGBanner />
        </SVGContainer>
      </div> */}
      <div
        className={'filter-svg'}
        onClick={() => {
          filter === 'ac' ? setDCFilter() : setACFilter();
        }}
      >
        {filter === 'ac' ? <SVGACFilter /> : <SVGDCFilter />}
      </div>
    </div>
  );
}
