export const lightTheme = {
  body: '#FFF',
  text: '#2c2b2b',
  toggleBorder: '#FFF',
  background: '#363537',

  navBarBg: '#f8f9fa',
  navText: 'rgba(0,0,0,.5)',
  translateBg: '#f8f9fa',
  translateText: '#393939',

  powerMetricColor: '#8f00ff',
  offerLinksColor: '#8f00ff',

  footerColorLink: '#1b85f7',

  colorSpan: '#8f00ff',

  a: '#2c2b2b',
};
export const darkTheme = {
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  background: '#999',

  navBarBg: '#292829',
  navText: '#FAFAFA',
  translateBg: '#292829',
  translateText: '#FAFAFA',

  powerMetricColor: '#d1a1f7',

  offerLinksColor: '#ddb9fa',

  footerColorLink: '#73b4fa',

  colorSpan: '#ddb9fa',

  a: '#fafafa',
};

export const redesignedDarkTheme = {
  ...darkTheme,
  body: '#292929',
};
