/* eslint-disable */
import React, {useState} from 'react';
import './contactsPage.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faTelegram,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import {faPhoneSquareAlt} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {Col, Container, Row} from 'react-bootstrap';
import ModalContacts from './modal/ModalContacts';
import ReactGA from 'react-ga';

export default function ContactsPage() {
  const [modalOpen, setModalOpen] = useState();

  const { t } = useTranslation();
  return (
    <Container id="contactsPage" style={{ height: '80vh' }} className="d-flex align-items-center  mw-100">
      <div className="mx-auto">
        <Row id="contactsPagePhoneTelegramBox" className="justify-content-between mb-5" style={{ width: '80vw' }}>
          <Col
            id="contactsPagePhoneBox"
            as={'div'}
            className="text-center viber"
            onClick={() => {
              setModalOpen(true);
              ReactGA.event({
                category: 'phoneNumbersModal',
                action: 'open phone number modal',
              });
            }}
          >
            <FontAwesomeIcon id="contactsPagePhoneIcon" icon={faPhoneSquareAlt} size="4x" />
            <p id="contactsPagePhoneTitle" className="linkName">
              {t('callUs')}
            </p>
          </Col>

          <Col
            id="contactsPageTelegramBox"
            as={'a'}
            href="https://t.me/km220_bot"
            className="text-center telegram"
            onClick={() => {
              ReactGA.event({
                category: 'telegram',
                action: 'telegram',
              });
            }}
          >
            <FontAwesomeIcon id="contactsPageTelegramIcon" icon={faTelegram} size="4x" />
            <p id="contactsPageTelegramTitle" className="linkName">
              {t('telegram')}
            </p>
          </Col>
        </Row>
        <Row id="contactsPageWatsappInstaBox" className="justify-content-between" style={{ width: '80vw' }}>
          <Col
            id="contactsWatsappBox"
            as={'a'}
            href={`https://wa.me/380971983759`}
            className="text-center watsapp"
            onClick={() => {
              ReactGA.event({
                category: 'watsapp',
                action: 'watsapp',
              });
            }}
          >
            <FontAwesomeIcon id="contactsPageWatsappBox" icon={faWhatsapp} size="4x" />
            <p id="contactsPageWatsappTitle" className="linkName">
              {t('whatsApp')}
            </p>
          </Col>

          <Col
            id="contactsPageInstaBox"
            as={'a'}
            href="https://www.instagram.com/220_km_com/"
            className="text-center instagram"
            onClick={() => {
              ReactGA.event({
                category: 'instagram',
                action: 'instagram',
              });
            }}
          >
            <FontAwesomeIcon id="contactsPageInstaIcon" icon={faInstagram} size="4x" />
            <p id="contactsPageInstaTitle" className="linkName">
              {t('instagram')}
            </p>
          </Col>

          <ModalContacts modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </Row>
      </div>
    </Container>
  );
}
