/* eslint-disable */
// noinspection SpellCheckingInspection
import { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import './layout.css';
import { useTranslation } from 'react-i18next';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import { Footer, FooterLink, GlobalStyles, LinksColor, NavBar, NavLink, Translate } from './globalStyles';
import { darkTheme, redesignedDarkTheme } from './darkTheme/Theme';
import { useLocalStorage } from '../hookServices/useLocalStorage';
import ReactGA, { event } from 'react-ga';
import { SVGLogo } from './svgComponents/SVGLogo';
import { api, useGetBalanceQuery, useGetInProgressOrdersCountQuery } from '../redux/api';
import { useGetClientId } from '../hookServices/clientId';

import { Icon } from '@ui';

export default function Layout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const clientId = useGetClientId();

  //todo replace this hack by redux
  const { data: freshBalance } = useGetBalanceQuery(clientId, { skip: !clientId, pollingInterval: 7000 });
  const { data: inProgressOrdersCount } = useGetInProgressOrdersCountQuery(clientId, {
    skip: !clientId,
    pollingInterval: 7000,
  });

  let toggleStatus = !open ? 'toggle-icon' : 'open toggle-icon ';

  const toggleMenu = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const lngs = {
    ua: { nativeName: 'Укр' },
    en: { nativeName: 'Eng' },
  };

  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  let stationNumber = searchParams.get('station');

  const { data: stationStatus } = api?.endpoints?.getStationStatus?.useQueryState({
    stationNumber,
  });

  // eslint-disable-next-line
  const [togglerStatus, setTogglerStatus] = useLocalStorage('themeTogglerStatus', false);
  const [theme, setTheme] = useLocalStorage('themeMode', 'light');
  // const [togglerStatus] = useLocalStorage('themeTogglerStatus', false);
  // const [, setTheme] = useLocalStorage('themeMode', 'light');
  const hasCharging = true;

  const darkModeSetter = () => {
    setTheme('dark');
  };
  const lightModeSetter = () => {
    setTheme('light');
  };

  const themeToggler = () => {
    setTogglerStatus(true);
    if (theme === 'light') {
      darkModeSetter();
    } else {
      lightModeSetter();
    }
  };

  useEffect(() => {
    if (togglerStatus === false) {
      if (stationStatus?.uiNightMode === false) {
        lightModeSetter();
      } else if (stationStatus?.uiNightMode === true) {
        darkModeSetter();
      }
    }
    // eslint-disable-next-line
  }, [stationStatus?.uiNightMode]);

  function isIos() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on isIos 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  return (
    // todo implement theme changes
    // <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
    <ThemeProvider theme={redesignedDarkTheme}>
      <>
        <GlobalStyles />
        <Container
          id="layout"
          fluid
          style={{
            ...{
              fontFamily: 'var(--font-family)',
              fontWeight: '500',
              fontSize: '18px',
              color: '#fff',
              letterSpacing: '0.15em',
            },
            // height: '100vh',
          }}
          className="justify-content-center ml-0 pl-0 mr-0 pr-0"
        >
          <NavBar id="layoutNavBar" className="fixed-top" expand="lg" collapseOnSelect expanded={open}>
            <div className="nav-conteiner">
              <div className="nav-glow"></div>

              <Navbar.Toggle
                id="layoutBurgerBtn"
                onClick={toggleMenu}
                bsPrefix={`${toggleStatus}`}
                style={{ position: 'absolute', top: '14px', left: '22px' }}
              />
              <LinksColor
                id="layoutLogoLink"
                onClick={() => {
                  ReactGA.event({
                    category: 'logo',
                    action: 'landing from logo click',
                  });
                  closeMenu();
                }}
                to={pathname === '/' ? `list/?station=${stationNumber}` : `/?station=${stationNumber}`}
              >
                <div id="layoutLogoImgBox" className="logoContainer">
                  <SVGLogo theme={theme} />
                </div>

                {/* <h3 id="layoutLogoText" className="ml-1 mb-0 logoText">
                220-km.com
              </h3> */}
              </LinksColor>

              <div
                className={'balance-container'}
                onClick={() => {
                  ReactGA.event({
                    category: 'header',
                    action: 'history form header',
                  });
                  if (hasCharging) {
                    navigate('/history');
                  }
                  closeMenu();
                }}
              >
                <div className={inProgressOrdersCount > 0 ? 'blink_me' : null}>
                  <p className={'textBalance'}>
                    <>
                      {freshBalance ? freshBalance / 100 : 0} {t('currency')}
                    </>
                  </p>
                </div>
              </div>
            </div>

            <Navbar.Collapse id="layoutHeaderCollapsedMenu" className="collapsed-menu">
              <Nav id="layoutHeaderMenu" className="ml-auto mt-auto align-items-center collapsed-menu-content">
                {clientId && (
                  <Nav.Item>
                    <Nav.Link
                      className="nav-link links"
                      onClick={() => {
                        closeMenu();
                        ReactGA.event({
                          category: 'history',
                          action: 'history page',
                        });
                      }}
                      eventKey="4"
                      as={Link}
                      to={`/history?station=${stationNumber}`}
                    >
                      <NavLink>{t('history')}</NavLink>
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    style={{ textDecoration: 'none' }}
                    className="nav-link links"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: 'home',
                        action: 'landing from burger',
                      });
                    }}
                    eventKey="1"
                    as={Link}
                    to={stationNumber === null ? '/' : `/?station=${stationNumber}`}
                  >
                    <NavLink>{t('landingLinkMap')}</NavLink>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ textDecoration: 'none' }}
                    className="nav-link links"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: 'home',
                        action: 'landing from burger',
                      });
                    }}
                    eventKey="1"
                    as={Link}
                    to={stationNumber === null ? '/list' : `/list?station=${stationNumber}`}
                  >
                    <NavLink>{t('landingLink')}</NavLink>
                  </Nav.Link>
                </Nav.Item>
                {/*todo show charging session based on client ui id.*/}
                {/*{stationNumber !== "null" && stationNumber !== null && (*/}
                {/*  <Nav.Item>*/}
                {/*    <Nav.Link*/}
                {/*      style={{ textDecoration: "none" }}*/}
                {/*      className="nav-link links"*/}
                {/*      onClick={() => {*/}
                {/*        closeMenu();*/}
                {/*        ReactGA.event({*/}
                {/*          category: "charge",*/}
                {/*          action: "start or charging page",*/}
                {/*        });*/}
                {/*      }}*/}
                {/*      eventKey="2"*/}
                {/*      as={Link}*/}
                {/*      to={`/start?station=${stationNumber}`}*/}
                {/*    >*/}
                {/*      <NavLink>{t("chargeLink")}</NavLink>*/}
                {/*    </Nav.Link>*/}
                {/*  </Nav.Item>*/}
                {/*)}*/}
                <Nav.Item>
                  <Nav.Link
                    className="nav-link links"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: 'contacts',
                        action: 'contacts page',
                      });
                    }}
                    eventKey="3"
                    as={Link}
                    to={`/contacts?station=${stationNumber}`}
                  >
                    <NavLink>{t('contacts')}</NavLink>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ textDecoration: 'none' }}
                    className="nav-link links"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: 'offer',
                        action: 'offer page',
                      });
                    }}
                    eventKey="5"
                    as={Link}
                    to={`/contract`}
                  >
                    <NavLink>{t('offer')}</NavLink>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ textDecoration: 'none' }}
                    className="nav-link links"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: 'b2b',
                        action: 'b2b page',
                      });
                    }}
                    eventKey="6"
                    as={Link}
                    to={`/b2b?station=${stationNumber}`}
                  >
                    <NavLink>{t('b2bHeader')}</NavLink>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item id="layoutNavBarChangeLangBox" className="ml-3 mr-3">
                  <Nav.Link
                    id="layoutNavBarChangeLangNavLink"
                    className="text-decoration-none"
                    eventKey="7"
                    onClick={event => {
                      event.stopPropagation();
                      ReactGA.event({
                        category: 'lang',
                        action: 'change language',
                      });
                    }}
                  >
                    {Object.keys(lngs).map((lng, i) => (
                      <div key={lng}>
                        <Translate
                          id={`layoutNavBar${lngs[lng].nativeName}Btn`}
                          className={i18n.resolvedLanguage === lng ? 'btnLang active' : 'btnLang'}
                          key={lng}
                          type="submit"
                          onClick={() => i18n.changeLanguage(lng)}
                        >
                          {lngs[lng].nativeName}
                        </Translate>
                        {i < lng.length - 1 && <Icon i="lngEarth" size="auto" className="leng-icon" />}
                      </div>
                    ))}
                  </Nav.Link>
                </Nav.Item>

                {/*<Nav.Item id="layoutNavBarChangeThemeBox">*/}
                {/*  <Nav.Link id="layoutNavBarChangeThemeNavLink" eventKey="8" onClick={closeMenu}>*/}
                {/*    <button*/}
                {/*      id="layoutNavBarChangeThemeBtn"*/}
                {/*      style={{*/}
                {/*        backgroundColor: 'transparent',*/}
                {/*        border: 'none',*/}
                {/*        marginTop: '2px',*/}
                {/*      }}*/}
                {/*      onClick={() => {*/}
                {/*        themeToggler();*/}
                {/*        ReactGA.event({*/}
                {/*          category: 'theme',*/}
                {/*          action: 'change theme',*/}
                {/*        });*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      {theme === 'light' ? <SVGNightMode /> : <SVGLightMode />}*/}
                {/*    </button>*/}
                {/*  </Nav.Link>*/}
                {/*</Nav.Item>*/}
              </Nav>
            </Navbar.Collapse>
          </NavBar>

          <div>
            <div
              className="nav-overlay"
              style={{
                display: open ? 'block' : 'none',
                opacity: open ? '0.9' : '0',
              }}
              onClick={closeMenu}
            ></div>
            <Outlet />
          </div>
        </Container>
      </>
    </ThemeProvider>
  );
}
