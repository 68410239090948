import React, { useEffect, useState } from 'react';
import { useInterval } from '../../hookServices/useInterval';

const Timer = props => {
  const [[h, m, s], setTime] = useState([props.hours, props.minutes, props.seconds]);
  const timerText = {
    ...props.style,
    fontWeight: '400',
    fontSize: props.fontSize,
    margin: props.margin,
  };

  const tick = () => {
    if (props.over || props.onlineStatus === false) return;

    if (h === 0 && m === 0 && s === 0) {
      props.setOver(true);
    } else if (props.state === 'DONE' || props.state === 'FAILED' || props.leftS <= 3) {
      setTime([0, 0, 0]);
    } else if (m === 0 && s === 0) {
      setTime([h - 1, 59, 59]);
    } else if (s === 0) {
      setTime([h, m - 1, 59]);
    } else {
      setTime([h, m, s - 1]);
    }
  };

  useEffect(() => {
    tick();
    // eslint-disable-next-line
  }, [props.onlineStatus]);

  useEffect(() => {
    if (props.onlineStatus === false) return;
    setTime([props.hours, props.minutes, props.seconds]);
    // eslint-disable-next-line
  }, [props.hours, props.onlineStatus]);

  useEffect(() => {
    if (props.onlineStatus === false) return;
    if (props.hours === 0) {
      setTime([props.hours, props.minutes, props.seconds]);
    }
  }, [props.hours, props.minutes, props.seconds, props.onlineStatus]);

  useInterval(() => {
    tick();
  }, 1000);

  return (
    <div id="timerBox" style={{ textAlign: 'center' }}>
      <p id="timerContent" style={timerText}>
        {`${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
      </p>
    </div>
  );
};

export default Timer;
