import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';
import ErrorPage from '../../errorPage/ErrorPage';
import Spinner from '../../../components/loaders/globalSpinner/Spinner';
import { useGetUserMeQuery } from '../../../redux/api';

export default function RedirectLogin({ fingerPrint }) {
  const [skip, setSkip] = useState(true);
  const [searchParams] = useSearchParams();

  const userToken = searchParams.get('session_id');

  const { data: userInfo, isError: isErrorUserInfo } = useGetUserMeQuery({ userToken, fingerPrint }, { skip });

  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem('session_id', userToken);
    if (userToken) {
      setSkip(false);
    }
  }, [userToken]);

  return (
    <>
      {isErrorUserInfo ? (
        <ErrorPage errorHeader={t('errorDevHeader')} errorBody={t('errorDevBody')} />
      ) : (
        <>
          <Spinner />
          {userInfo ? <Navigate to={'/b2b'} /> : null}
        </>
      )}
    </>
  );
}
