import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const webCameraApi = createApi({
  reducerPath: 'webCameraApi',
  baseQuery: fetchBaseQuery({ baseUrl: `https://220-km-stage.com:3333` }),
  endpoints: builder => ({
    getWebCamLink: builder.query({
      query: () => ({
        url: '/api/stream/hls',
        responseHandler: response => response.text(),
      }),
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetWebCamLinkQuery: useGetTuyaWebCamLinkQuery } = webCameraApi;
