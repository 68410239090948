/* eslint-disable */
import React from 'react';
import {useTranslation} from 'react-i18next';
import Emoji from '../../../components/emoji/Emoji';
import Modal from '../../../components/modal/Modal';
import '../contactsPage.css';
import ReactGA from 'react-ga';
import {ModalTextColorLink} from '../../../components/globalStyles';
import {
  EV_TUNING_PHONE_NUM,
  MAX_PHONE_NUM,
  NAZAR_PHONE_NUM
} from '../../../utils/contacts';
import {splitToCorrectNumber} from '../../../utils/utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import {Col} from "react-bootstrap";

export default function ModalContacts({modalOpen, setModalOpen}) {
  const {t} = useTranslation();

  return (
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div id="modalContactsBox" className="telephoneContainer">
          <p id="modalContactsTitle" className="tapForCall">
            {t('tapCall')}:
          </p>
          <div id="modalContactsMaxPhoneNumberBox" className="numberCont">
            <Emoji symbol="📲" label="phone"/>
            <ModalTextColorLink
                id="modalContactsNumberLinkMax"
                href={MAX_PHONE_NUM ? `tel:${MAX_PHONE_NUM[0]}` : '#'}
                className="telephone"
                onClick={() => {
                  ReactGA.event({
                    category: 'call',
                    action: 'call to MAIN',
                  });
                }}
            >
              {MAX_PHONE_NUM ? splitToCorrectNumber(MAX_PHONE_NUM[1]) : t(
                  'error')}
            </ModalTextColorLink>
          </div>
          <div id="modalContactsDimaPhoneNumberBox" className="numberCont">
            <Emoji symbol="📲" label="phone"/>
            <ModalTextColorLink
                id="modalContactsNumberLinkDima"
                href={NAZAR_PHONE_NUM ? `tel:${NAZAR_PHONE_NUM[0]}` : '#'}
                className="telephone"
                onClick={() => {
                  ReactGA.event({
                    category: 'call',
                    action: 'call to Nazar',
                  });
                }}
            >
              {NAZAR_PHONE_NUM ? splitToCorrectNumber(NAZAR_PHONE_NUM[0]) : t(
                  'error')}
            </ModalTextColorLink>
          </div>
          <div id="modalContactsDimaPhoneNumberBox" className="numberCont">
            <Emoji symbol="📲" label="phone"/>
            <ModalTextColorLink
                id="modalContactsNumberLinkDima"
                href={EV_TUNING_PHONE_NUM ? `tel:${EV_TUNING_PHONE_NUM[0]}`
                    : '#'}
                className="telephone"
                onClick={() => {
                  ReactGA.event({
                    category: 'call',
                    action: 'call to EV tuning',
                  });
                }}
            >
              {EV_TUNING_PHONE_NUM ? splitToCorrectNumber(
                  EV_TUNING_PHONE_NUM[0]) : t('error')}
            </ModalTextColorLink>
          </div>
          <br/>
          <p id="modalContactsTitle" className="tapForCall">
            {t('tapChatBot')}:
          </p>

          <Col
              id="contactsPageTelegramBox"
              as={'a'}
              href="https://t.me/km220_bot"
              className="text-center telegram"
              onClick={() => {
                ReactGA.event({
                  category: 'telegram',
                  action: 'telegram',
                });
              }}
          >
            <FontAwesomeIcon id="contactsPageTelegramIcon" icon={faTelegram}
                             size="4x"/>
            <p id="contactsPageTelegramTitle" className="linkName">
              {t('telegram')}
            </p>
          </Col>
        </div>
      </Modal>
  );
}
