import { setCookie } from './cookiesManager';

const convertLocationToString = position => {
  const posData = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  };

  return JSON.stringify(posData);
};

const getGeolocation = (handleSuccess, handleError) =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setCookie('userLocation', convertLocationToString(position), 120);
        handleSuccess(position);
        resolve(position);
      },
      error => {
        handleError(error);
        reject(error);
      }
    );
  });

export async function getGeolocationWithPermission(getAllStations, handleGeolocationError, handleGeolocationSuccess) {
  try {
    if (navigator.permissions && navigator.permissions.query) {
      const result = await navigator.permissions.query({ name: 'geolocation' });
      switch (result.state) {
        case 'granted':
          return await getGeolocation(handleGeolocationSuccess, handleGeolocationError);
        case 'prompt':
          return await getGeolocation(handleGeolocationSuccess, handleGeolocationError);

        case 'denied':
          handleGeolocationError('Permission denied');
          return getAllStations();

        default:
          throw new Error('Unknown permission state');
      }
    } else {
      return await getGeolocation(handleGeolocationSuccess, handleGeolocationError);
    }
  } catch (error) {
    console.error('Error in geolocation permission querying:', error);
    return getAllStations();
  }
}
