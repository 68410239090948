import cn from 'classnames';
import styles from './button.module.css';

export const Button = ({ children, className, variant, onClick, link, ...props }) => {
  const handleClick = event => {
    if (link) {
      window.location.href = link;
    } else if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      type="button"
      className={cn(
        styles.button,
        variant === 'outline' && styles.outline,
        variant === 'outline-alt' && styles.outlineAlt,
        className
      )}
      onClick={handleClick}
      {...props}
    >
      {link ? (
        <a href={link} style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {children}
        </a>
      ) : (
        children
      )}
    </button>
  );
};
