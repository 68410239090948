/* eslint-disable */
import React, {useState} from 'react';
import './GetPower.css';
import '../../../components/buttons/mainBtn.css';
import {Col} from 'react-bootstrap';
import {
  PowerMetricsColor
} from '../../../components/globalStyles';
import {useTranslation} from 'react-i18next';
import ReactGA from 'react-ga';
import {ENV} from '../../../environment';
import {Button} from "../../../shared/ui";

export default function CongratsFail({
  kWtCharged,
  carKwtKmRatio,
  chargeStatus,
  stationNumber
}) {
  const [modalOpen, setModalOpen] = useState();

  let finishBtn = 'mainBtn';
  let startBtnText = 'mainBtnText';

  const {t} = useTranslation();

  const openModal = () => {
    ReactGA.event({
      category: 'calibration',
      action: 'open calibration modal',
    });
    if (ENV === 'prod') {
      console.log('close');
    } else {
      setModalOpen(true);
    }
  };

  const styleModalBtn = ENV === 'prod' ? 'disableModalBtn' : `${finishBtn}`;

  const styleModalBtnText = ENV === 'prod' ? 'disableModalBtnText'
      : `${startBtnText}`;

  return (
      <div id="offlineStationContainer">
        <Col id="offlineStationBox" xs="auto" lg="auto" className="text-center">
          <PowerMetricsColor id="offlineStationCongratsTitle"
                             className="finishTitle">
            {t('powerExceededError')}{' '}
          </PowerMetricsColor>
          <Button className={'mainBtnText'} link={`/start?station=${stationNumber}`}>
            {t('startNew')}
          </Button>
        </Col>

      </div>
  );
}
