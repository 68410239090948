// https://products.aspose.app/words/picresize/svg
export default function SVGACFilter() {
  return (
    <svg width="130" height="65" viewBox="0 0 863 436" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="863" height="436" rx="73.0041" fill="#202020" opacity="0.9" />
      <g filter="url(#filter0_di_2096_79)">
        <path
          d="M119.395 281.14L175.674 154.872H204.536L260.996 281.14H230.33L184.152 169.663H195.697L149.338 281.14H119.395ZM147.534 254.083L155.291 231.896H220.229L228.166 254.083H147.534ZM338.174 283.305C328.434 283.305 319.354 281.742 310.936 278.615C302.639 275.368 295.423 270.798 289.29 264.906C283.157 259.013 278.347 252.099 274.86 244.162C271.493 236.225 269.809 227.506 269.809 218.006C269.809 208.506 271.493 199.787 274.86 191.85C278.347 183.914 283.157 176.999 289.29 171.106C295.544 165.214 302.819 160.704 311.117 157.578C319.414 154.331 328.494 152.707 338.355 152.707C349.298 152.707 359.159 154.631 367.938 158.479C376.837 162.207 384.292 167.739 390.305 175.075L371.545 192.392C367.216 187.461 362.406 183.793 357.115 181.388C351.823 178.863 346.051 177.6 339.798 177.6C333.905 177.6 328.494 178.562 323.563 180.486C318.633 182.41 314.364 185.176 310.756 188.784C307.148 192.392 304.322 196.661 302.278 201.591C300.354 206.522 299.392 211.993 299.392 218.006C299.392 224.019 300.354 229.49 302.278 234.421C304.322 239.351 307.148 243.62 310.756 247.228C314.364 250.836 318.633 253.602 323.563 255.526C328.494 257.45 333.905 258.412 339.798 258.412C346.051 258.412 351.823 257.209 357.115 254.804C362.406 252.279 367.216 248.491 371.545 243.44L390.305 260.757C384.292 268.093 376.837 273.684 367.938 277.533C359.159 281.381 349.238 283.305 338.174 283.305Z"
          fill="url(#paint0_linear_2096_79)"
          fillOpacity="0.99"
        />
        <path
          d="M119.395 281.14L175.674 154.872H204.536L260.996 281.14H230.33L184.152 169.663H195.697L149.338 281.14H119.395ZM147.534 254.083L155.291 231.896H220.229L228.166 254.083H147.534ZM338.174 283.305C328.434 283.305 319.354 281.742 310.936 278.615C302.639 275.368 295.423 270.798 289.29 264.906C283.157 259.013 278.347 252.099 274.86 244.162C271.493 236.225 269.809 227.506 269.809 218.006C269.809 208.506 271.493 199.787 274.86 191.85C278.347 183.914 283.157 176.999 289.29 171.106C295.544 165.214 302.819 160.704 311.117 157.578C319.414 154.331 328.494 152.707 338.355 152.707C349.298 152.707 359.159 154.631 367.938 158.479C376.837 162.207 384.292 167.739 390.305 175.075L371.545 192.392C367.216 187.461 362.406 183.793 357.115 181.388C351.823 178.863 346.051 177.6 339.798 177.6C333.905 177.6 328.494 178.562 323.563 180.486C318.633 182.41 314.364 185.176 310.756 188.784C307.148 192.392 304.322 196.661 302.278 201.591C300.354 206.522 299.392 211.993 299.392 218.006C299.392 224.019 300.354 229.49 302.278 234.421C304.322 239.351 307.148 243.62 310.756 247.228C314.364 250.836 318.633 253.602 323.563 255.526C328.494 257.45 333.905 258.412 339.798 258.412C346.051 258.412 351.823 257.209 357.115 254.804C362.406 252.279 367.216 248.491 371.545 243.44L390.305 260.757C384.292 268.093 376.837 273.684 367.938 277.533C359.159 281.381 349.238 283.305 338.174 283.305Z"
          fill="#FC019B"
        />
        <path
          d="M119.395 281.14L175.674 154.872H204.536L260.996 281.14H230.33L184.152 169.663H195.697L149.338 281.14H119.395ZM147.534 254.083L155.291 231.896H220.229L228.166 254.083H147.534ZM338.174 283.305C328.434 283.305 319.354 281.742 310.936 278.615C302.639 275.368 295.423 270.798 289.29 264.906C283.157 259.013 278.347 252.099 274.86 244.162C271.493 236.225 269.809 227.506 269.809 218.006C269.809 208.506 271.493 199.787 274.86 191.85C278.347 183.914 283.157 176.999 289.29 171.106C295.544 165.214 302.819 160.704 311.117 157.578C319.414 154.331 328.494 152.707 338.355 152.707C349.298 152.707 359.159 154.631 367.938 158.479C376.837 162.207 384.292 167.739 390.305 175.075L371.545 192.392C367.216 187.461 362.406 183.793 357.115 181.388C351.823 178.863 346.051 177.6 339.798 177.6C333.905 177.6 328.494 178.562 323.563 180.486C318.633 182.41 314.364 185.176 310.756 188.784C307.148 192.392 304.322 196.661 302.278 201.591C300.354 206.522 299.392 211.993 299.392 218.006C299.392 224.019 300.354 229.49 302.278 234.421C304.322 239.351 307.148 243.62 310.756 247.228C314.364 250.836 318.633 253.602 323.563 255.526C328.494 257.45 333.905 258.412 339.798 258.412C346.051 258.412 351.823 257.209 357.115 254.804C362.406 252.279 367.216 248.491 371.545 243.44L390.305 260.757C384.292 268.093 376.837 273.684 367.938 277.533C359.159 281.381 349.238 283.305 338.174 283.305Z"
          fill="url(#paint1_linear_2096_79)"
          fillOpacity="0.21"
        />
      </g>
      <g filter="url(#filter1_ii_2096_79)">
        <path
          d="M479.596 278.433V152.165H536.958C550.667 152.165 562.752 154.81 573.215 160.101C583.677 165.272 591.854 172.548 597.747 181.928C603.639 191.308 606.586 202.431 606.586 215.299C606.586 228.046 603.639 239.17 597.747 248.67C591.854 258.05 583.677 265.385 573.215 270.677C562.752 275.848 550.667 278.433 536.958 278.433H479.596ZM508.818 254.442H535.515C543.932 254.442 551.208 252.879 557.341 249.752C563.594 246.505 568.405 241.935 571.772 236.043C575.259 230.15 577.003 223.236 577.003 215.299C577.003 207.242 575.259 200.327 571.772 194.555C568.405 188.662 563.594 184.153 557.341 181.026C551.208 177.779 543.932 176.156 535.515 176.156H508.818V254.442ZM694.463 280.598C684.723 280.598 675.643 279.034 667.226 275.908C658.928 272.661 651.713 268.091 645.579 262.199C639.446 256.306 634.636 249.391 631.149 241.454C627.782 233.518 626.098 224.799 626.098 215.299C626.098 205.799 627.782 197.08 631.149 189.143C634.636 181.206 639.446 174.292 645.579 168.399C651.833 162.507 659.108 157.997 667.406 154.87C675.704 151.623 684.783 150 694.644 150C705.587 150 715.448 151.924 724.227 155.772C733.126 159.5 740.581 165.032 746.594 172.368L727.834 189.684C723.505 184.754 718.695 181.086 713.404 178.681C708.112 176.156 702.34 174.893 696.087 174.893C690.194 174.893 684.783 175.855 679.852 177.779C674.922 179.703 670.653 182.469 667.045 186.077C663.437 189.684 660.611 193.953 658.567 198.884C656.643 203.814 655.681 209.286 655.681 215.299C655.681 221.312 656.643 226.783 658.567 231.714C660.611 236.644 663.437 240.913 667.045 244.521C670.653 248.129 674.922 250.894 679.852 252.819C684.783 254.743 690.194 255.705 696.087 255.705C702.34 255.705 708.112 254.502 713.404 252.097C718.695 249.572 723.505 245.784 727.834 240.733L746.594 258.05C740.581 265.385 733.126 270.977 724.227 274.825C715.448 278.674 705.527 280.598 694.463 280.598Z"
          fill="url(#paint2_linear_2096_79)"
          fillOpacity="0.21"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_2096_79"
          x="19.3945"
          y="52.7072"
          width="470.91"
          height="330.598"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="50" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0 0 0 0 0 0.909804 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2096_79" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2096_79" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-5.15381" dy="-10.3076" />
          <feGaussianBlur stdDeviation="26.2844" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0 0 0 0 0 0.909804 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2096_79" />
        </filter>
        <filter
          id="filter1_ii_2096_79"
          x="474.442"
          y="139.692"
          width="272.153"
          height="144.513"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-5.15381" dy="-10.3076" />
          <feGaussianBlur stdDeviation="26.2844" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0 0 0 0 0 0.909804 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2096_79" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.15381" />
          <feGaussianBlur stdDeviation="1.80383" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect1_innerShadow_2096_79" result="effect2_innerShadow_2096_79" />
        </filter>
        <linearGradient
          id="paint0_linear_2096_79"
          x1="254.85"
          y1="152.707"
          x2="254.85"
          y2="283.305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C83DC5" />
          <stop offset="1" stopColor="#932D90" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2096_79"
          x1="254.85"
          y1="152.707"
          x2="254.85"
          y2="283.305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA4242" stopOpacity="0" />
          <stop offset="0.514" stopColor="#FF0000" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2096_79"
          x1="613.095"
          y1="150"
          x2="613.095"
          y2="280.598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA4242" stopOpacity="0" />
          <stop offset="0.514" stopColor="#FF0000" />
        </linearGradient>
      </defs>
    </svg>
  );
}
