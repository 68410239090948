// import { OfferLinksColor } from "../../components/globalStyles";
// import styles from "../termsPage/TermsPage.module.css";
import React from 'react';

export const QAB2b = () => {
  return (
    <div />
    // <div
    //   className="pb-5 pt-2 pl-4 pr-2"
    //   style={{ maxWidth: "600px", padding: "20px 20px 40px 20px" }}
    // >
    //   <p>
    //     <strong>
    //       <u>1. Яка потужність потрібна?</u>
    //     </strong>
    //
    //     <ul>
    //       <li>
    //         Станції 220-km.com розраховані на 2-3 кВт (як чайник) і можуть
    //         працювати тільки вночі;
    //       </li>
    //     </ul>
    //   </p>
    //
    //   <p>
    //     <strong>
    //       <u>2. Яка комісія за користування платформою 220-km.com ?</u>
    //     </strong>
    //     <ul>
    //       <li>Встановлення і обслуговування станцій - безкоштовно;</li>
    //       <li>
    //         Оренда обладнання перший рік безкоштовно, далі 5 % з кіловату;
    //       </li>
    //       <li>
    //         Комісія складає від 5 до 20 % з кіловату в залежності від кількості
    //         станцій і місць розташування;
    //       </li>
    //     </ul>
    //   </p>
    //   <p>
    //     <strong>
    //       <u>3. Який місячний прибуток?</u>
    //     </strong>
    //     <ul>
    //       <li>3 станції в середньому будуть приносити 400 $ в місяць;</li>
    //     </ul>
    //   </p>
    //
    //   <p>
    //     <strong>
    //       <u>4. Як контролювати споживання електроенергії?</u>
    //     </strong>
    //     <ul>
    //       <li>
    //         В електронному кабінеті власника станції{" "}
    //         <OfferLinksColor
    //           className={styles.offerLink}
    //           href="http://220-km.com/b2b"
    //           target="_blank"
    //         >
    //           http://220-km.com/b2b
    //         </OfferLinksColor>
    //         ;
    //       </li>
    //       <li>Як доп опцію, можна встановити повірений лічильник;</li>
    //     </ul>
    //   </p>
    //
    //   <p>
    //     <strong>
    //       <u>5. Як буде захищений електромобіль від крадіїв та вандалів?</u>
    //     </strong>
    //     <ul>
    //       <li>
    //         Ми пропонуємо відеонагляд і охорону 24/7 як доп опцію, ось приклад
    //         такої станції:{" "}
    //         <OfferLinksColor
    //           className={styles.offerLink}
    //           href="http://220-km.com/start?station=2"
    //           target="_blank"
    //         >
    //           http://220-km.com/start?station=2
    //         </OfferLinksColor>
    //         ;
    //       </li>
    //     </ul>
    //   </p>
    //
    //   <p>
    //     <strong>
    //       <u>6. Що з електробезпекою?</u>
    //     </strong>
    //     <ul>
    //       <li>
    //         Дифреле, що вимкне станцію у віпадку доторкання до фазового
    //         провідніка людиною;
    //       </li>
    //       <li>
    //         Захист від перевантаження, підвищеної напруги, IP54, адаптивний
    //         режим захисту від просадки напруги;
    //       </li>
    //       <li>
    //         Запас міцності комутуючих реле і проводки зменьшує вірогідність
    //         травмування при аварії до нуля;
    //       </li>
    //     </ul>
    //   </p>
    // </div>
  );
};
