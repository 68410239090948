/* eslint-disable */
//  todo insert to db
export default {
  ua: {
    portNote_2: 'розетка або тайп 2 кабель',
  },
  ru: {
    portNote_2: 'розетка або тайп 2 кабель',
  },
  en: {
    portNote_2: 'euro plug or Type 2 cable',
  },
};
